import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { language } from '@vezeeta/enterprise-store/lib/types/language';

// eslint-disable-next-line import/prefer-default-export
export const withTranslation = WrappedComponent => {
  const Localization = props => {
    const [localization, setLocalization] = useState(undefined);
    useEffect(() => {
      const { isUserLoaded, user } = props;
      if (isUserLoaded) {
        fetch(`/localization/${language}/${language}-${user.CountryIsoCode}.json`)
          .then(response => response.json())
          .then(locale => {
            setLocalization(locale);
          })
          .catch(() => {
            fetch(`/localization/${language}/${language}-EG.json`)
              .then(response => response.json())
              .then(locale => {
                setLocalization(locale);
              })
              .catch(() => {});
          });
      }
    }, [props]);

    return <WrappedComponent localization={localization} />;
  };

  Localization.propTypes = {
    isUserLoaded: PropTypes.bool,
    user: PropTypes.object,
  };

  const mapStateToProps = state => ({
    isUserLoaded: state.user.loaded,
    user: state.user.user,
  });

  return connect(mapStateToProps, null)(Localization);
};
