/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';

export const extendContentContainer = css`
  ${props => props.theme.media.tablet`
    padding: 0px;
  `};
  ${props => props.theme.media.phone`
    padding: 0px;
  `};
`;
