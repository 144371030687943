import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FeatureFlagContainer, SetGlobalStyle } from '@vezeeta/web-components';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import EnterpriseLayout from '@vezeeta/enterprise-layout';
import '@vezeeta/enterprise-modals/lib/Modal.css';
import { EntityContainer, UserContainerForm } from 'app/Routes';
import { MixpanelConsumer } from 'react-mixpanel';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
import '@vezeeta/web-components/lib/shared/Utils.css';
import { withTranslation } from 'app/withTranslation/withTranslation';
import RoleBasedRoute from 'app/RoleBasedAccess/RoleBasedRoute';
import { getFeatureFlags, getbreadCrumbSteps, isSupportSingleLanguage } from './App.helper';
import { extendContentContainer } from './App.style';

const App = ({ ...props }) => {
  useEffect(() => {
    SetGlobalStyle();
  }, []);

  const { user, isUserLoaded, localization } = props;
  const supportSingleLanguage = isSupportSingleLanguage(user, isUserLoaded);

  return (
    <FeatureFlagContainer.Provider value={getFeatureFlags()}>
      <Router>
        <EnterpriseLayout
          breadcrumbProps={getbreadCrumbSteps(localization, language)}
          supportSingleLanguage={supportSingleLanguage}
          activeTab="Entity"
          extendContentContainer={extendContentContainer}
        >
          <MixpanelConsumer>
            {mixpanel => (
              <React.Fragment>
                <Switch>
                  <RoleBasedRoute
                    path="/"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <EntityContainer
                        {...routeProps}
                        localization={localization.EntityManagment}
                        language={language}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/new"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <UserContainerForm
                        {...routeProps}
                        localization={localization.addEditDoctor}
                        language={language}
                        mixpanel={mixpanel}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/edit"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <UserContainerForm
                        {...routeProps}
                        localization={localization.addEditDoctor}
                        language={language}
                        mixpanel={mixpanel}
                      />
                    )}
                  />
                  <Redirect to="/" />
                </Switch>
              </React.Fragment>
            )}
          </MixpanelConsumer>
        </EnterpriseLayout>
      </Router>
    </FeatureFlagContainer.Provider>
  );
};

App.propTypes = {
  user: PropTypes.object,
  isUserLoaded: PropTypes.bool,
  localization: PropTypes.object,
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
});

export default withTranslation(connect(mapStateToProps)(App));
