import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

export const EntityContainer = Loadable({
  loader: () => import('views/entity/EntityContainer'),
  loading: Loading,
});

export const UserContainerForm = Loadable({
  loader: () => import('views/user/UserContainer'),
  loading: Loading,
});
